/* ==========================================
    SWIPER.JS
========================================== */
.swiper {
    &-padding {
        .swiper-wrapper {
            padding-top: 3rem;
            padding-bottom: 6.5rem;
        }
    }

    .swiper-button-prev.swiper-custom-nav,
    .swiper-button-next.swiper-custom-nav {
        width: 7rem;
        height: 3.5rem;
        background: lighten($gray-200, 4%);
        transition: all 0.3s;
        bottom: 0;
        top: auto;
        color: $black;

        &::after {
            font-size: 1rem;
            color: $gray-200;
            display: none;
        }

        &:hover {
            color: $primary;
        }
    }

    .swiper-button-prev.swiper-custom-nav {
        right: auto;
        left: calc(50% - 7rem);
    }
    .swiper-button-next.swiper-custom-nav {
        right: auto;
        left: 50%;
    }

    .swiper-slide-active {
        .destination {
            transform: scaleY(1.1);
            // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

            &-inner {
                transform: none;
            }

            h2,
            p {
                transform: scaleY(0.9);
            }
        }
    }
}
