/*
*
* =====================
* GENERAL
* =====================
*
*/

.text-sm {
    font-size: 0.85rem !important;
}

.btn {
    font-family: $headings-font-family;
}

a {
    text-decoration: none;
    transition: all 0.3s;
}

section {
    overflow: hidden;
}

.form-control {
    &::placeholder {
        font-size: 0.85rem;
    }
}

.text-gray {
    color: #aaa !important;
}

.destination {
    display: block;
    position: relative;
    overflow: hidden;
    height: 24rem;
    transition: all 0.2s;
    transform-origin: center center;
    text-decoration: none;

    &::before {
        display: block;
        content: '';
        transition: all 0.3s;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 40%,
            rgba(0, 0, 0, 0.99)
        );
        @include position-fit();
    }

    &:hover {
        text-decoration: none;
        .destination-inner {
            transform: none;
        }
    }
}

.destination-inner {
    transition: all 0.3s;
    transform: translateY(3.2rem);

    @include media-breakpoint-down(md) {
        transform: none;
    }
}

.center {
    .destination {
        transform: scaleY(1.1);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

        &-inner {
            transform: none;
        }

        h2,
        p {
            transform: scaleY(0.9);
        }
    }
}

.category {
    display: flex;
    height: 5rem;
    align-items: center;
    justify-content: center;
    &-title {
        margin-bottom: 0;
        padding: 0.5rem 1.5rem;
        background: #fff;
    }
}

.sponsor {
    max-width: 4rem;
    transition: all 0.3s;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.instagram-item {
    width: 33.33333%;
}

.dropdown-toggle::after {
    vertical-align: middle;
}

.navbar .dropdown-menu {
    margin-top: 0.4rem;
}

.instagram-item-overlay {
    @include position-fit();
    transition: all 0.3s;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
}

.instagram-item {
    position: relative;
    &:hover {
        .instagram-item-overlay {
            opacity: 1;
        }
    }
}

.subscribe-img {
    max-width: 300px;
    border: 10px solid #fff;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    &:last-of-type {
        position: absolute;
        top: 1rem;
        left: 5rem;
        transform: rotate(-15deg);
        @include media-breakpoint-down(md) {
            left: 2.5rem;
            max-width: 280px;
        }
    }
}

.navbar {
    .nav-link {
        font-family: $headings-font-family;
    }
}

.dropdown-toggle {
    &::after {
        content: '\f107';
        border: none;
        vertical-align: middle;
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        font-size: 0.9em;
    }
}
