/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils 
   ------------------------
*/

.text-uppercase {
    @include uppercase;
    letter-spacing: 0.1em;
}

.text-sm {
    font-size: $font-size-sm;
}

.text-base {
    font-size: $font-size-base;
}

.text-lg {
    font-size: $font-size-lg;
}

.text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
    text-shadow: 2px 2px 2px rgba($primary, 0.1);
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-inherit {
    color: inherit;
}

/*
    Rounded corners - lg, sm 
   ------------------------
*/

.rounded-lg {
    border-radius: $border-radius-lg;
}

.rounded-sm {
    border-radius: $border-radius-sm;
}

.rounded-xl {
    border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix} {
            border: $border-width solid $border-color !important;
        }
    }
}

/*
    Opacity helpers - .opacity-[1-9]   
    ------------------------
*/

$opacity: 1 2 3 4 5 6 7 8 9 10;

@each $current-opacity in $opacity {
    .opacity-#{$current-opacity} {
        opacity: #{$current-opacity/10};
    }
}

/*
    Z-index helpers - .z-index-[10-50]   
    ------------------------
*/

$z-index: 10 20 30 40 50;

@each $current-z-index in $z-index {
    .z-index-#{$current-z-index} {
        z-index: #{$current-z-index};
    }
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/

.letter-spacing-0 {
    letter-spacing: 0 !important;
}

$spacing: 0.1em 0.2em 0.3em 0.4em 0.5em;

@each $current-spacing in $spacing {
    $i: index($spacing, $current-spacing);

    .letter-spacing-#{$i} {
        letter-spacing: #{$current-spacing};
    }
}

/*
     Colours and backgrounds
    ------------------------
*/

@each $color, $value in $theme-colors {
    .text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    a.text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    .bg-#{$color}-light {
        background-color: theme-color-level($color, -11);
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color, $value in $grays {
    .bg-gray-#{$color} {
        background: $value !important;
    }

    .text-gray-#{$color} {
        color: $value;
    }
}

@each $color, $value in $theme-colors {
    .badge-#{$color}-light {
        color: $value;
        background-color: theme-color-level($color, -11);
    }
}

/*
     Transparent overlays
    ------------------------
*/

.overlay-content {
    position: relative;
    z-index: 20;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            position: relative;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                background: #fff;
            }
        }
    }

    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            &::after {
                transition: $transition-fade;
                opacity: 0;
            }
        }
    }

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix}:hover {
        @include media-breakpoint-up($next) {
            &::after {
                opacity: 0.4;
            }
        }
    }

    .light-overlay#{$infix}-0,
    .overlay-hover-light#{$infix}-0:hover {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }

    .dark-overlay#{$infix} {
        position: relative;

        @include media-breakpoint-up($next) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: 0.6;
                background: #000;
            }
        }
    }

    .dark-overlay#{$infix}-0 {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }
}

/*
     Other
    ------------------------
*/

.overflow-visible {
    overflow: visible !important;
}

.shadow-0 {
    box-shadow: none !important;
}

.bg-cover {
    background-size: cover !important;
    background-position: center center !important;
}

.bg-center {
    background-position: 50% 50% !important;
}

.index-forward {
    position: relative;
    z-index: 99;
}

.border-gray {
    border-color: #eee !important;
}

.reset-anchor {
    color: inherit;
    border: none;
    background: none;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.bg-none {
    background: none !important;
}

.bg-darker {
    background: darken($dark, 2%) !important;
}

.list-check {
    list-style: none;
    li {
        padding-left: 1.5rem;
        position: relative;
        &::before {
            content: '\f058';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            color: $primary;
            font-family: 'Font Awesome\ 5 Free';
            font-weight: 900;
        }
    }
}

ol.list-numbers {
    list-style: none;
    padding-left: 0;
    counter-reset: my-awesome-counter;
}
ol.list-numbers li {
    counter-increment: my-awesome-counter;
    position: relative;
    padding-left: 3.5rem;
}
ol.list-numbers li::before {
    content: counter(my-awesome-counter) '. ';
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
}

.bg-full-left {
    position: relative;
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -48.5rem;
        width: 50rem;
        height: 100%;
        background: $light;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.has-transition {
    transition: all 0.3s;
}

.first-letter-styled::first-letter {
    font-size: 3rem;
    font-weight: $font-weight-bold;
    line-height: 1;
}

.line-height-md {
    line-height: 1.5em;
}
.line-height-lg {
    line-height: 1.8em;
}

.blockquote-custom {
    font-size: 1.15rem;
    color: #999;
    padding-left: 4rem;
    position: relative;
    padding-top: 1.5rem;

    &::before {
        content: '\f10d';
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        font-size: 2.5rem;
        color: $primary;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
}

$socialColors: (
    'facebook': #3b5999,
    'twitter': #55acee,
    'instagram': #e4405f,
    'youtube': #cd201f,
);

.social-link-share {
    padding: 0.45rem 1.2rem;
    color: #fff;
    background: $dark;
    transition: all 0.3s;
    font-size: 0.8rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    &:hover,
    &:focus {
        text-decoration: none;
        color: #fff;
    }
}

@each $color, $value in $socialColors {
    .#{$color} {
        &:hover,
        &:focus {
            background: $value;
        }
    }
}

.comments {
    & > li > ul {
        margin-left: 70px;
    }
}
